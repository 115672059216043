// Lightbox
@import "~ngx-lightbox/lightbox.css";

// Select 2
@import "~@ng-select/ng-select/themes/default.theme.css";

// Date Picker
@import "~flatpickr/dist/flatpickr.css";

// LeafLet Chart
@import "leaflet/dist/leaflet.css";

// Emoji Picker
// @import '~@ctrl/ngx-emoji-mart/picker';

$activeColor: #50a5f1;
$switchColor: #ffffff;
$bgColor: #585e72;
$bgColorSecondary: #151b2c;
$borderColor: #3f4454;
$activeTextColor: #ffffff;
$offTextColor: #a6b0cf;
$disabledBgColor: #f8f8fb;
$disabledTextColor: #343a40;
$seperatorTopColor: #495261;
$seperatorBottomColor: #101011;

.emoji-mart {
  position: absolute;
  bottom: 20px;
}

.emoji-mart-preview {
  display: none;
}

// dropdown
.dropdown-toggle {
  &:after {
    display: none;
  }
}

.modal-backdrop {
  z-index: 1002 !important;
}

.dropdown-toggle::after {
  display: none;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: #fff !important;
  color: #495057 !important;
}

// ----- Advance Ui --------//
// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray-600);
}

.star {
  position: relative;
  display: inline-block;
}

.star_fill {
  color: $gray-500;
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray-600);
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}

// Calendar
.flatpickr-calendar.open {
  z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked > .switch-pane > span {
  color: #fff;
}

.switch.switch-small > .switch-pane > span {
  font-size: 14px;
}

// Ck Editer
.ck-editor__editable {
  min-height: 245px !important;
}

.custom-accordion {
  .accordion-list {
    padding: 0px;
    background-color: transparent;
    margin-bottom: 20px;
  }
}

.ng-select .ng-select-container {
  // background-color: #2a3042 !important;
  background-color: var(--input-bg) !important;
  // color: #c3cbe4 !important;
  color: var(--input-text) !important;
  // border-color: #353d55 !important;
  border-color: var(--input-border) !important;
}

.ng-dropdown-panel {
  // background-color: #2a3042 !important;
  background-color: var(--input-bg) !important;
  // border-color: #353d55 !important;
  border-color: var(--input-border) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  // background-color: #2a3042 !important;
  background-color: var(--input-bg) !important;
  // color: #c3cbe4 !important;
  color: var(--input-text) !important;
  // border-color: #353d55 !important;
  border-color: var(--input-border) !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  // background-color: #393f55 !important;
  background-color: var(--input-selected-bg) !important;
  // color: #d6dbeb !important;
  color: var(--input-text) !important;
}

[data-bs-theme="dark"] {
  .ng-select .ng-select-container {
    // background-color: #2a3042 !important;
    background-color: var(--input-bg) !important;
    // color: #c3cbe4 !important;
    color: var(--input-text) !important;
    // border-color: #353d55 !important;
    border-color: var(--input-border) !important;
  }

  .ng-dropdown-panel {
    // background-color: #2a3042 !important;
    background-color: var(--input-bg) !important;
    // border-color: #353d55 !important;
    border-color: var(--input-border) !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    // background-color: #2a3042 !important;
    background-color: var(--input-bg) !important;
    // color: #c3cbe4 !important;
    color: var(--input-text) !important;
    // border-color: #353d55 !important;
    border-color: var(--input-border) !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    // background-color: #393f55 !important;
    background-color: var(--input-selected-bg) !important;
    // color: #d6dbeb !important;
    color: var(--input-text) !important;
  }
}

//dropzone
.dropzone > .dropzone.dz-wrapper .dz-message {
  background: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
}

// ck editor
.ck.ck-toolbar {
  background-color: var(--#{$prefix}light) !important;
  border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable {
  background-color: var(--#{$prefix}custom-white) !important;
  color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
  background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: $input-border-color !important;
}

.bs-datepicker-head {
  background-color: transparent !important;
}

.bs-datepicker-head button {
  color: var(--calendar-text) !important;
}

.bs-datepicker-container {
  // background-color: #32394e !important;
  background-color: var(--calendar-bg) !important;
}

.bs-datepicker-body {
  span:not(.disabled){
    color: var(--calendar-text) !important;
  }
  span:not(.disabled):hover{
    background-color: var(--calendar-hover) !important;
  }
  span.selected:not(.disabled):hover{
    color: var(--calendar-text) !important;
  }
  // span.is-other-month{
  //   color: var(--calendar-disabled) !important;
  // }
  span.disabled{
    color: var(--calendar-disabled) !important;
  }
  span.selected{
    background-color: var(--calendar-selected) !important;
    color: var(--calendar-selected-text) !important;
  }
  span.is-highlighted{
    background-color: transparent !important;
  }
}

// Dark Mode Css
[data-bs-theme="dark"] {
  .bs-datepicker {
    box-shadow: none !important;
  }

  .bs-datepicker-container {
    // background-color: #32394e !important;
    background-color: var(--calendar-bg) !important;
  }

  .bs-datepicker-head button {
    // color: #c3cbe4 !important;
    color: var(--calendar-text) !important;
  }

  .bs-datepicker-body {
    border: 1px solid transparent;
    span:not(.disabled){
      color: var(--calendar-text) !important;
    }
    span:not(.disabled):hover{
      background-color: var(--calendar-hover) !important;
    }
    span.selected:not(.disabled):hover{
      color: var(--calendar-text) !important;
    }
    span.disabled{
      color: var(--calendar-disabled) !important;
    }
    span.selected{
      background-color: var(--calendar-selected) !important;
      color: var(--calendar-selected-text) !important;
    }
    span.is-highlighted{
      background-color: transparent !important;
    }
  }

  .auth-logo .auth-logo-light {
    display: block;
  }

  .auth-logo .auth-logo-dark {
    display: none;
  }

  // Accordion
  .accordion {
    --bs-accordion-color: #fff;
    --bs-accordion-bg: transparent;
  }

  .accordion-button {
    color: var(--bs-body-color);
  }
}

.page-link {
  display: inline-table;
}

//   vertical tab

.vertical-tab {
  ul {
    display: inline-block;
    position: relative;
    z-index: 1;
    border: 0;

    @media (max-width: 1024px) {
      display: block;
    }

    li {
      a {
        padding: 8px 50px;

        @media (max-width: 1440px) {
          padding: 8px 30px;
        }
      }
    }
  }

  .tab-content {
    position: absolute;
    top: 0;

    @media (max-width: 1024px) {
      position: inherit;
    }
  }
}

.ecommerce-checkout-tab {
  ul {
    display: inline-block;
    float: left;
    margin-right: 20px;
    border: 0;

    @media (max-width: 600px) {
      display: block;
      float: inherit;
    }
  }

  li {
    a {
      padding: 1px 80px;
    }
  }
}

.product-detai-imgs {
  ul {
    border: 0;

    a {
      border: 0 !important;
      width: 130px;

      @media (max-width: 425px) {
        width: 50px;
      }
    }
  }
}

.tooltip,
.tooltip-arrow {
  position: absolute;
}

[data-layout-size="boxed"] {
  .vertical-tab {
    ul {
      display: inline-block;
      position: relative;
      z-index: 1;
      border: 0;

      @media (max-width: 1024px) {
        display: block;
      }

      li {
        a {
          padding: 8px 50px;
        }
      }
    }
  }
}

.owl-theme .owl-nav [class*="owl-"] {
  width: 30px;
  height: 30px;
  line-height: 28px !important;
  font-size: 30px !important;
  border-radius: 50% !important;
  background-color: transparent !important;
  color: #ffff !important;
  justify-content: center !important;
  margin: 4px 8px !important;
  display: inline-flex !important;
  align-items: center !important;
}

.tab-bg {
  .nav-tabs {
    background-color: var(--#{$prefix}light);
    margin-bottom: 20px;
  }
}

// popover
.bs-popover-top,
.bs-popover-bottom {
  margin-left: -8px !important;
}

.bs-popover-left .arrow,
.bs-popover-right .arrow {
  margin-top: -8px !important;
}

.custom-widget-nav {
  .nav-tabs {
    justify-content: flex-end;
    margin-top: -1.5rem;
  }
}

.custom-vertical-tabs {
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .nav-tabs {
    flex-direction: column;

    @media (min-width: 992px) {
      flex-shrink: 0;
    }
  }

  .tab-content {
    @media (min-width: 992px) {
      flex-grow: 1;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 16px;

    .nav-tabs,
    .tab-content {
      width: 100%;
    }
  }
}

// nav-justify-center

.nav-justify-center {
  .nav-tabs {
    justify-content: center;
  }
}

.ngx-slider .ngx-slider-bar {
  background-color: var(--#{$prefix}tertiary-bg) !important;
}

#backdrop {
  &.offcanvas-backdrop {
    display: none;

    &.show {
      display: inline-block;
    }
  }
}

.enablePointer:hover {
  cursor: pointer;
}

.text-color-menu {
  @include media-breakpoint-up(md) {
    color: #000 !important;
  }
  @include media-breakpoint-up(lg) {
    color: #fff !important;
  }
}

.tabBorderPrimary {
  .nav-tabs .nav-item .nav-link {
    border-color: #556ee6;
    border-bottom: 0;
  }
  .nav-tabs {
    border-color: #556ee6;
  }
}

.tabPrimaryFilled {
  .nav-link:not(.active) {
    // color: #a6b0cf !important;
    // background-color: #2a3042 !important;
    color: var(--offcanvas-inactive-text) !important;
    background-color: var(--offcanvas-bg) !important;
    border-radius: unset !important;
  }

  .active:not(.offcanvas-primary) {
    // background-color: #2a3042 !important;
    // color: #fff !important;
    background-color: var(--offcanvas-bg) !important;
    color: var(--offcanvas-active-text) !important;

    .nav-link::after {
      content: "";
      // background: #fff !important;
      background: var(--tab-selected) !important;
      border-radius: unset !important;
    }
  }
}
.dropdown-menu[data-popper-placement^="left-top"] {
  bottom: 100% !important;
  top: auto !important;
  left: auto !important;
  right: 0 !important;
  animation: none !important;
}
.dropdown-menu[data-popper-placement^="right-top"] {
  bottom: 100% !important;
  top: auto !important;
  left: 0 !important;
  right: auto !important;
  animation: none !important;
}

.background-builder {
  // background: url('../../../assets/images/bck-grnd-avatar.png');
  background-repeat: repeat;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.offcanvas-primary {
  // background-color: $bgColor !important;
  background-color: var(--offcanvas-primary) !important;
  // color: $activeTextColor !important;
  color: var(--offcanvas-header-text) !important;
  select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    position: relative !important;
    &::after {
      width: 0 !important;
      height: 0 !important;
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-top: 6px solid #f00 !important;
      position: absolute !important;
      top: 40% !important;
      right: 5px !important;
      content: "";
      z-index: 98 !important;
    }
  }
  &:disabled {
    background-color: unset !important;
    color: unset !important;
  }

  // &:read-only{
  //   background-color: unset !important;
  //   color: unset !important;
  // }
  &::file-selector-button {
    background-color: $bgColor !important;
    color: $activeTextColor !important;
  }
}

.surface-color {
  // background-color: $bgColor !important;
  background-color: var(--surface-bg) !important;
  // color: $activeTextColor !important;
  color: var(--surface-text) !important;
  select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    position: relative !important;
    &::after {
      width: 0 !important;
      height: 0 !important;
      border-left: 6px solid transparent !important;
      border-right: 6px solid transparent !important;
      border-top: 6px solid #f00 !important;
      position: absolute !important;
      top: 40% !important;
      right: 5px !important;
      content: "";
      z-index: 98 !important;
    }
  }
  &:disabled {
    background-color: unset !important;
    color: unset !important;
  }

  // &:read-only{
  //   background-color: unset !important;
  //   color: unset !important;
  // }
  &::file-selector-button {
    background-color: $bgColor !important;
    color: $activeTextColor !important;
  }
}

.offcanvas-primary-select {
  background-color: $bgColor !important;
  color: $activeTextColor !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  position: relative !important;
  &::after {
    width: 0 !important;
    height: 0 !important;
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top: 6px solid #f00 !important;
    position: absolute !important;
    top: 40% !important;
    right: 5px !important;
    content: "";
    z-index: 98 !important;
  }

  &:disabled {
    background-color: unset !important;
    color: unset !important;
  }
}
.offcanvas-ck-primary {
  .ck.ck-editor__main {
    background-color: $body-tertiary-bg-dark !important;
  }
  .ck.ck-toolbar {
    background-color: $body-tertiary-bg-dark !important;
    border: 1px solid $borderColor !important;
  }

  .ck-reset_all :not(.ck-reset_all-excluded *),
  .ck.ck-reset_all,
  .ck.ck-reset {
    background-color: $body-tertiary-bg-dark !important;
    color: rgb(166, 176, 207) !important;
    .ck-button {
      color: rgb(166, 176, 207) !important;
    }
  }

  .ck.ck-editor__main > .ck-editor__editable {
    background-color: $body-secondary-bg-dark !important;
    color: $activeTextColor !important;
  }

  .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
    border-color: $borderColor !important;
  }

  .ck.ck-button:not(.ck-disabled):hover,
  a.ck.ck-button:not(.ck-disabled):hover,
  .ck.ck-button.ck-on,
  a.ck.ck-button.ck-on {
    background-color: $body-secondary-bg-dark !important;
  }

  .ck.ck-toolbar .ck.ck-toolbar__separator {
    background: $borderColor !important;
  }
}
.offcanvas-border-primary {
  border-color: $borderColor !important;
}
.offcanvas-secondary {
  background-color: $bgColorSecondary;
  color: $offTextColor;
  &:disabled {
    background-color: $disabledBgColor !important;
    color: $disabledTextColor !important;
  }
}

.border-3d {
  border-top: 1px solid var(--offcanvas-3d-top) !important;
  border-bottom: 1px solid var(--offcanvas-3d-bottom) !important;
}
.border-top-3d {
  border-top: 1px solid var(--offcanvas-3d-top) !important;
}
.border-bottom-3d {
  border-bottom: 1px solid var(--offcanvas-3d-bottom) !important;
}

.disabled {
  background-color: transparent !important;
}

.offcanvas-border-secondary {
  border-color: $borderColor !important;
}

.offcanvas-width {
  width: 300px !important;
  // @media (max-width: 1200px) {
  //   width: 220px !important;
  // }
}

.cta {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 2px solid #e05951;
  // background-color: #e05951;
  // color: #fff;
  background: var(--settings-btn-bg);
  color: var(--settings-btn-text);
  transition: width 0.7s;
  overflow: hidden;
  i {
    opacity: 1;
    transition: opacity 0.5s;
  }
  .button-text {
    opacity: 0;
    transition: opacity 0.5s;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    width: 100px;
    transition: width 0.7s;
    i {
      opacity: 0;
      transition: opacity 0.5s;
    }
    .button-text {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }
}

.offcanvase-side {
  .main-content {
    margin-left: $sidebar-collapsed-width;
  }

  .navbar-brand-box {
    padding: 0 1.5rem;
    text-align: center;
  }

  // Side menu
  .vertical-menu {
    position: absolute;
    width: $sidebar-collapsed-width !important;
    z-index: 5;

    .simplebar-mask,
    .simplebar-content-wrapper {
      overflow: visible !important;
    }

    .simplebar-scrollbar {
      display: none !important;
    }

    .simplebar-offset {
      bottom: 0 !important;
    }

    // Sidebar Menu
    #sidebar-menu {
      .menu-title,
      .badge,
      .collapse.in {
        display: none !important;
      }

      .nav.collapse {
        height: inherit !important;
      }

      .has-arrow {
        &:after {
          display: none;
        }
      }

      > ul {
        > li {
          position: relative;
          white-space: nowrap;

          > a {
            padding: 15px 20px;
            min-height: 55px;
            transition: none;

            &:hover,
            &:active,
            &:focus {
              color: $sidebar-menu-item-hover-color;
            }

            i {
              font-size: 1.45rem;
              margin-left: 4px;
            }

            span {
              display: none;
              padding-left: 25px;
            }
          }

          &:hover {
            > a {
              position: relative;
              width: calc(190px + #{$sidebar-collapsed-width});
              color: $primary;
              background-color: darken($sidebar-bg, 4%);
              transition: none;

              i {
                color: $primary;
              }

              span {
                display: inline;
              }
            }

            > ul {
              display: block;
              left: $sidebar-collapsed-width;
              position: absolute;
              width: 190px;
              height: auto !important;
              box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);

              ul {
                box-shadow: 3px 5px 10px 0 rgba(54, 61, 71, 0.1);
              }

              a {
                box-shadow: none;
                padding: 8px 20px;
                position: relative;
                width: 190px;
                z-index: 6;
                color: $sidebar-menu-sub-item-color;

                &:hover {
                  color: $sidebar-menu-item-hover-color;
                }
              }
            }
          }
        }

        ul {
          padding: 5px 0;
          z-index: 9999;
          display: none;
          background-color: $sidebar-bg;

          li {
            &:hover {
              > ul {
                display: block;
                left: 190px;
                height: auto !important;
                margin-top: -36px;
                position: absolute;
                width: 190px;
              }
            }

            > a {
              span.pull-right {
                position: absolute;
                right: 20px;
                top: 12px;
                transform: rotate(270deg);
              }
            }
          }

          li.active {
            a {
              color: $gray-100;
            }
          }
        }
      }
    }
  }
}

.animated-btn {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  line-height: 58px;
  display: inline-block;
  text-align: center;
  // background: #585e72;
  background: var(--module-bar-bg);
  position: absolute;
  cursor: pointer;
  left: -30px;
  z-index: 1;
}

.animated-btn::before,
.animated-btn::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  // background: rgba(42, 48, 66, 0.8);
  background: var(--module-bar-ripple-before);
  animation: ripple-1 2s infinite ease-in-out;
  z-index: -1;
}

.animated-btn::after {
  // background: rgba(42, 48, 66, 0.6);
  background: var(--module-bar-ripple-after);
  animation: ripple-2 2s infinite ease-in-out;
  animation-delay: 0.5s;
}

@keyframes ripple-1 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes ripple-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}

.builder-button {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  right: 0;
  .bar {
    width: 140px;
    height: 54px;
    // background: #585e72;
    background: var(--module-bar-bg);
    padding-left: 44px;
    // color: #fff;
    color: var(--module-bar-text);
    display: flex;
    align-items: center;
    @media (max-width: 1200px) {
      width: 80px !important;
    }
    @media (max-width: 992px) {
      width: 140px !important;
    }
  }
}

.animate-fede-out {
  animation: fadeout-33 500ms ease-in-out forwards;
}

@keyframes fadeout-33 {
  /* Basic move left and right*/
  0% {
    right: -12px;
  }
  // 25% {right: -25%;}
  // 50% {right: -50%;}
  // 75% {right: -75%;}
  100% {
    right: -100%;
  }
}
.animate-fede-in {
  animation: fadein-33 500ms ease-in-out forwards;
}

@keyframes fadein-33 {
  /* Basic move left and right*/
  0% {
    right: -100%;
  }
  // 25% {right: -25%;}
  // 50% {right: -50%;}
  // 75% {right: -75%;}
  100% {
    right: -12px;
  }
}

.rowStyle {
  height: 180px !important;
}

.button-dropzone {
  height: 100% !important;
  border: 1px dashed #eff2f7 !important;
  background: #fff !important;
  border-radius: 6px !important;
  text-align: center;

  .dz-message {
    width: 100%;
    margin: 1em 0;
  }
}

.curser-pointer {
  cursor: pointer !important;
}

.responsave-font-size {
  @media (max-width: 1200px) {
    .card-title {
      font-size: 12px !important;
    }
    .card-text {
      font-size: 10px !important;
    }
    font-size: 10px !important;
  }
}

.responsave-font-size_xl {
  @media (max-width: 1440px) {
    .card-title {
      font-size: 11px !important;
    }
    .card-text {
      font-size: 10px !important;
    }
    font-size: 10px !important;
  }
}

.font-smaller {
  .card-title {
    font-size: 12px !important;
  }
  .card-text {
    font-size: 10px !important;
  }
  font-size: 10px !important;
}

.custom-body-height {
  min-height: 100vh !important;
}

.stiky-postion {
  position: sticky;
  top: 118px;
}

.card-btn-toggle {
  button {
    display: none !important;
  }
  &:hover {
    button {
      display: block !important;
    }
  }
}

.dropzone-custom {
  min-height: 230px !important;
  border: 2px dashed #eff2f7 !important;
  background: $bgColor !important;
  border-radius: 6px !important;
  text-align: center;
  .dz-wrapper {
    background: $bgColor !important;
  }
  .dz-message {
    width: 100%;
    margin: 1em 0;
    .dz-text {
      color: #fff;
      font-size: 15px;
    }
  }
}

.not-allowed {
  cursor: not-allowed !important;
}

// .grid-custom {
//   display: grid;
//   grid-template-columns: repeat(auto-fill, minmax(var(--grid-col-size), 1fr));
//   gap: var(--grid-gap);
// }

// .grid-columns {
//   grid-template-columns: repeat(var(--grid-col-size), 1fr) !important;
//   @media (max-width: 425px) {
//     grid-template-columns: repeat(var(--grid-col-size-sm), 1fr) !important;
//   }
// }

.grid-custom {
  display: grid;
  grid-template-columns: repeat(var(--grid-col-size), minmax(0,1fr));
  gap: var(--grid-gap);
  @media (max-width: 425px) {
    grid-template-columns: repeat(var(--grid-col-size-sm), 1fr);
  }
}

.media-hover {
  &:hover {
    background-color: #f7f7f7;
  }
}
.media-selected {
  background-color: #f7f7f7;
}

.button-hover {
  &:hover {
    background: var(--bs-tertiary-bg);
  }
}

.redOnHover:hover {
  color: red;
}

.hover-action {
  &:hover {
    color: var(--hover-action-color) !important;
  }
}

.hover-btn {
  transition: all 300ms ease-out;
  &:hover {
    color: var(--hover-text-color) !important;
    background-color: var(--hover-bg-color) !important;
  }
}

//Change this is _topbar.scss:
// @media (max-width: 319px) {
//   .navbar-brand-box {
//     display: none;
//   }
// }

// body{
//   background-color: #f8f8fb;
// }

:root {
  --card-body: #ffffff;
  --card-body-dark: #2a3042;
  --card-text: #343a40;
  --card-text-dark: #ffffff;

  --modal-bg: #ffffff;

  --card-channel-body: #ffffff;
  --card-channel-text: #343a40;
  --card-button-hover-bg: #343a40;
  --card-dim-bg: #ffffff;
  --card-bright-bg: #ffffff;
  --card-container-text: #000000;

  --slider-card: #ffffff;

  --subcard: #74788d;
  --offcanvas-subcard: #74788d;

  --surface: #f8f8fb;
  --surface-dark: #222736;

  --sidebar: #2a3042;
  --sidebar-text: #a6b0cf;
  --sidebar-active-text: #ffffff;
  --sidebar-hover-text: #ffffff;
  --sidebar-sub-menu-text: #79829c;
  --sidebar-icon: #6a7187;
  --sidebar-menu-bg: #2a3042;

  --offcanvas-bg: #2a3042;
  --offcanvas-content-bg: #2a3042;
  --offcanvas-parent-bg: #2a3042;
  --offcanvas-icon-bg: #2a3042;
  --offcanvas-submenu-bg: #2a3042;
  --offcanvas-card-container-bg: #2a3042;
  --offcanvas-card-container-hover-bg: #2a3042;
  --offcanvas-single-card-container-bg: #2a3042;
  --offcanvas-single-card-container-hover-bg: #2a3042;
  --offcanvas-text: #a6b0cf;
  --offcanvas-header: #3f4454;
  --offcanvas-header-text: #a6b0cf;
  --offcanvas-active-text: #ffffff;
  --offcanvas-hover-text: #ffffff;
  --offcanvas-inactive-text: #a6b0cf;
  --offcanvas-primary: #585e72;
  --offcanvas-divider: #383d4b;
  --offcanvas-sub-menu-text: #79829c;
  --offcanvas-icon: #6a7187;
  --offcanvas-scrollbar-color: #6a7187;
  --offcanvas-outline: #6a7187;
  --offcanvas-outline-variant: #6a7187;
  --offcanvas-drag-icon: #74788d;
  --offcanvas-radio-btn-bg: #74788d;
  --offcanvas-radio-btn-active-bg: #74788d;
  --offcanvas-error: #f46a6a;;

  --tab-selected: #ffffff;
  --tab-border-outline-variant: #ffffff;
  --os-tabset-bg: #ffffff;

  --icon-close: #000000;

  --disabled-shade: #343a40bf
  --disabled-shade-text: #ffffff

  --offcanvas-3d-top: #495261;
  --offcanvas-3d-bottom: #101011;

  --switch-bg: #585E72;
  --switch-border: #3f4454;
  --switch-checked-bg: #50a5f1;
  --switch-dot: #ffffff;
  --switch-checked-dot: #ffffff;
  --switch-checked-text: #ffffff;

  --topbar: #ffffff;
  --topbar-icon: #555b6d;
  --topbar-dark: #262b3c;

  --table-header-text: #a6b0cf;
  --table-header-bg: #32394e;
  --table-hear-border: #32394e;
  --table-bg: #ffffff;
  --table-striped-bg: #f8f9fa;
  --table-text: #ffffff;
  --table-hover-text: #000000;
  --table-hover-bg: #ffffff;

  --input-bg: #ffffff;
  --input-disabled-bg: #ffffff;
  --input-text: #000000;
  --input-border: #000000;
  --input-addon-bg: #32394e;
  --input-selected-bg: #585151;
  --input-placeholder: #BEC3E4;

  --module-bar-bg: #585e72;
  --module-bar-ripple-before: rgba(42, 48, 66, 0.8);
  --module-bar-ripple-after: rgba(42, 48, 66, 0.6);
  --module-bar-text: #ffffff;
  --module-bar-icon: #ffffff;

  --settings-btn-bg: #ffffff;
  --settings-btn-text: #ffffff;

  --icon-button-bg: #ffffff;
  --icon-button-icon: #000000;
  --icon-back-hover-bg: #000000;
  --media-drag-icon: #ffffff;

  --calendar-bg: #ffffff;
  --calendar-text: #000000;
  --calendar-hover: #74788d;
  --calendar-selected: #34c38f;
  --calendar-selected-text: #ffffff;
  --calendar-disabled: #74788d;

  --dropzone-bg: #ffffff;
  --dropzone-border: #000000;
  --dropzone-remove-text: #000000;

  --list-group-bg: #ffffff;
  --list-group-border: #000000;
  --list-group-text: #000000;

  --swal-bg: #74788d;

  --loader-bg: #74788d;

  --dropdown-bg: #74788d;
  --dropdown-text: #ffffff;
  --dropdown-hover-bg: #74788d;
  --dropdown-hover-text: #ffffff;
  --dropdown-border: #000000;

  --nav-link-hover-text: #3395ff;

  --md-sys-color-primary: #007aff;

  --chat-sidebar-bg: #ffffff;
  --chat-bar-bg: #ffffff;
  --chat-rcv-message-bg: #ffffff;
  --chat-input-bg: #ffffff;

  --channel-message-bg: #ffffff;
  --channel-post-radio-bg: #ffffff;
  --channel-post-radio-check: #ffffff;

  --chat-compose-bg: #ffffff;

  --primary: #34c38f;
  --onPrimary: #ffffff;
  --primaryContainer: #34c38f;
  --onPrimaryContainer: #ffffff;
  --secondary: #74788d;
  --onSecondary: #ffffff;
  --secondaryContainer: #74788d;
  --onSeconaryContainer: #ffffff;
  --tertiary: #50a5f1;
  --onTeriary: #ffffff;
  --tertiaryContainer: #74788d;
  --onTertiaryContainer: #ffffff;

  --success: #34c38f;
  --onSuccess: #ffffff;
  --warning: #f1b44c;
  --onWarning: #ffffff;
  --error: #f46a6a;
  --onError: #ffffff;
  --errorContainer: #f46a6a;
  --onErrorContainer: #ffffff;
  --outline: #ffffff;
  --outline-variant: #ffffff;

  --success-button-bg: #34c38f;
  --success-button-text: #ffffff;
  --success-container-button-bg: #34c38f;
  --success-container-button-text: #ffffff;

  --danger-button-bg: #f46a6a;
  --danger-button-text: #ffffff;
  --danger-button-hover-bg: #f46a6a;
  --danger-button-hover-text: #ffffff;

  --warning-button-bg: #f1b44c;
  --warning-button-text: #ffffff;
  --warning-button-hover-bg: #f1b44c;
  --warning-button-hover-text: #ffffff;

  --surface-text: #a6b0cf;
  --surface-bg: #585e72;

  --surfaceContainerHighest: #ffffff;
  --surfaceContainerHigh: #ffffff;
  --surfaceContainer: #ffffff;
  --surfaceContainerLow: #ffffff;
  --surfaceContainerLowest: #ffffff;
  --onSurface: #000000;

  --surfaceBright: #000000;
  --surfaceDim: #000000;

  /* "to right" => 90deg, with stops compressed into 0%–15%. */
  --line-glow-background-direction-to-right: linear-gradient(
    90deg,
    rgba(167, 217, 254, 0) 0%,   /* quickly transitions to transparent */
    #a9efff 6%,                  /* narrow band of visible color */
    #b2ffe6 10%,                 /* more color within a small range */
    rgba(178, 255, 230, 0) 15%   /* back to transparent by 15% */
  );

  /* "to bottom" => 180deg, matching the same compressed stops. */
  --line-glow-background-direction-to-bottom: linear-gradient(
    180deg,
    rgba(167, 217, 254, 0) 0%,
    #a9efff 6%,
    #b2ffe6 10%,
    rgba(178, 255, 230, 0) 15%
  );

  --line-glow-background-direction-to-right-dark: linear-gradient(
    90deg,
    rgba(167, 217, 254, 0) 0%,
    /* Same colors as light mode, but with low opacity */
    rgba(169, 239, 255, 0.13) 6%,
    rgba(178, 255, 230, 0.13) 10%,
    rgba(178, 255, 230, 0) 15%
  );

  --line-glow-background-direction-to-bottom-dark: linear-gradient(
    180deg,
    rgba(167, 217, 254, 0) 0%,
    /* Same colors as light mode, but with low opacity */
    rgba(169, 239, 255, 0.13) 6%,
    rgba(178, 255, 230, 0.13) 10%,
    rgba(178, 255, 230, 0) 15%
  );

  ////FlOW
  --node-background-color: #ffffff;
  --node-border-radius: 2px;
  --node-border-color: rgba(60, 60, 67, 0.36);
  --node-color: rgba(60, 60, 67, 0.78);
  --node-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.06);
  --connection-color: rgba(60, 60, 67, 0.78);
  --snap-connection-color: rgba(60, 60, 67, 0.38);
  --connection-gradient-1: #915930;
  --connection-gradient-2: #18794e;
  --outlet-color: #3451b2;
  --input-output-color: rgba(60, 60, 67, 0.78);
  --minimap-background-color: #ffffff;
  --minimap-node-color: rgba(60, 60, 67);
  --minimap-node-selected-color: #3451b2;
  --minimap-view-color: rgba(100, 108, 255, 0.14);
  &.dark {
    --node-background-color: #000000;
    --node-border-radius: 2px;
    --node-border-color: rgba(235, 235, 245, 0.38);
    --node-color: rgba(235, 235, 245, 0.6);
    --node-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.06);
    --connection-color: rgba(235, 235, 245, 0.6);
    --snap-connection-color: rgba(235, 235, 245, 0.2);
    --connection-gradient-1: #f9b44e;
    --connection-gradient-2: #3dd68c;
    --outlet-color: #a8b1ff;
    --input-output-color: rgba(235, 235, 245, 0.6);
    --minimap-background-color: #1b1b1f;
    --minimap-node-color: rgba(255, 255, 245, 0.86);
    --minimap-node-selected-color: #a8b1ff;
    --minimap-view-color: rgba(100, 108, 255, 0.16);
  }
}

.offcanvas-header-bg{
  background-color: var(--offcanvas-header) !important;
}

.offcanvas-header-text{
  color: var(--offcanvas-header-text) !important;
}

.offcanvas-divider{
  border-bottom: 1px solid var(--offcanvas-divider);
}

.switch.switch-small{
  background-color: var(--switch-bg) !important;
  border-color: var(--switch-border) !important;
}

.switch.switch-small.checked{
  background-color: var(--switch-checked-bg) !important;
  border-color: var(--switch-border) !important;
}

.switch small{
  background-color: var(--switch-dot) !important;
}

.switch.checked small{
  background-color: var(--switch-checked-dot) !important;
}

.switch .switch-pane .switch-label-unchecked{
  color: var(--switch-text) !important;
}

.switch .switch-pane .switch-label-checked{
  color: var(--switch-checked-text) !important;
}

.icon-close{
  color: var(--icon-close) !important;
}

.bg-dark{
  background-color: var(--disabled-shade) !important;
  opacity: var(--bs-bg-opacity);
  label{
    color: var(--disabled-shade-text);
  }
}

.nav-link.active:not(.activetab){
  // background-color: #343a40 !important;
  // color: #ffffff !important;
  background-color: var(--primary) !important;
  h6{
    color: var(--onPrimary) !important;
  }
  span{
    color: var(--onPrimary) !important;
  }
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.mode-tabset .nav-link.active:not(.activetab){
  // background-color: #343a40 !important;
  // color: #ffffff !important;
  background-color: var(--onSurface) !important;
  h6{
    color: var(--surfaceContainerLowest) !important;
  }
  span{
    color: var(--surfaceContainerLowest) !important;
  }
  i{
    color: var(--surfaceContainerLowest) !important;
  }
}

.offcanvas-tabset .nav-link.active:not(.activetab){
  // background-color: #343a40 !important;
  // color: #ffffff !important;
  border-bottom: var(--offcanvas-active-tab) !important;
  background-color: var(--offcanvas-submenu-bg) !important;
  h6{
    color: var(--offcanvas-active-tab-text) !important;
  }
  span{
    color: var(--offcanvas-active-tab-text) !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.primaryOnHover:hover{
  color: $primary;
  text-decoration: underline;
}

.form-control.is-invalid:not(.rounded-form){
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
  background-position: right calc(0.375em + 0.5rem) center !important;
}

.form-control.is-invalid.rounded-form{
  background-position: right calc(0.375em + 0.5rem) center !important;
}

.form-control.is-invalid:focus{
  box-shadow: none !important;
}

.auth-pass-toggle-inputgroup .form-control.is-invalid{
  background-image: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.auth-country-inputgroup .form-control.is-invalid{
  background-image: none !important;
}

.form-control.is-invalid + span{
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.input-border-danger{
  border-color: $danger !important;
}

.form-control{
  transition: none !important;
}

.card-header{
  background: var(--card-body);
}

.module-bar-icon{
  color: var(--module-bar-icon) !important;
}

.subcard{
  background: var(--subcard);
}

.offcanvas-subcard{
  background: var(--offcanvas-subcard);
}

.dropzone{
  background: none !important;
}

.icon-button{
  background: var(--icon-button-bg);
  color: var(--icon-button-icon)
}

.primary-hover-icon:hover{
  color: var(--primary) !important;
}

.success-icon{
  color: var(--success);
}

.success-hover-icon:hover{
  color: var(--success) !important;
}

.danger-icon{
  color: var(--error);
}

.danger-hover-icon:hover{
  color: var(--error);
}

.warning-icon{
  color: var(--warning) !important;
}

.warning-hover-icon:hover{
  color: var(--warning) !important;
}

.danger-text{
  color: var(--error);
}

.offcanvas-danger-text{
  color: var(--offcanvas-error);
}

.form-select:focus{
  box-shadow: none;
}

.success-button{
  background: var(--success-button-bg) !important;
  color: var(--success-button-text) !important;
}

.success-button:hover{
  background: hsl(from var(--success-button-bg) h s calc(l - 5)) !important;
  color: var(--success-button-text) !important;
}

.success-button:active{
  background: hsl(from var(--success-button-bg) h s calc(l - 7)) !important;
  color: var(--success-button-text) !important;
}

.tertiary-button{
  background: var(--tertiary) !important;
  color: var(--onTeriary) !important;
}

.tertiary-button:hover{
  background: hsl(from var(--tertiary) h s calc(l - 5)) !important;
  color: var(--onTeriary) !important;
}

.tertiary-button:active{
  background: hsl(from var(--tertiary) h s calc(l - 7)) !important;
  color: var(--onTeriary) !important;
}

.success-badge{
  background: var(--success-container-button-bg) !important;
  color: var(--success-container-button-text) !important;
}

.warning-button{
  background: var(--warning-button-bg) !important;
  color: var(--warning-button-text) !important;
}

.warning-button:hover{
  background: hsl(from var(--warning-button-bg) h s calc(l - 5)) !important;
  color: var(--warning-button-text) !important;
}

.warning-button:active{
  background: hsl(from var(--warning-button-bg) h s calc(l - 7)) !important;
  color: var(--warning-button-text) !important;
}

.danger-button{
  background: var(--danger-button-bg) !important;
  color: var(--danger-button-text) !important;
  transition: background-color 0.3s ease, filter 0.3s ease;
}

.danger-button:hover{
  background: hsl(from var(--danger-button-bg) h s calc(l - 10)) !important;
}

.danger-badge{
  background: var(--danger-container-button-bg) !important;
  color: var(--danger-container-button-text) !important;
}

.warning-badge{
  background: var(--warning-container-button-bg) !important;
  color: var(--warning-container-button-text) !important;
}

.primary-container-badge{
  background: var(--primaryContainer) !important;
  color: var(--onPrimaryContainer) !important;
}

.secondary-container-badge{
  background: var(--secondaryContainer) !important;
  color: var(--onSecondaryContainer) !important;
}

.tertiary-container-badge{
  background: var(--tertiaryContainer) !important;
  color: var(--onTertiaryContainer) !important;
}

.modal-content{
  border-radius: var(--bs-border-radius-xl) !important;
}

.primary-text{
  color: var(--primary);
  border-color: #262b3c;
}

.border-outline{
  border-color: var(--outline) !important;
}

.outline{
  border-color: var(--outline) !important;
}

.outline-variant{
  border-color: var(--outline-variant) !important;
}

.offcanvas-outline{
  border-color: var(--offcanvas-outline) !important;
}

.offcanvas-outline-variant{
  border-color: var(--offcanvas-outline-variant) !important;
}

input::placeholder{
  // color: var(--input-placeholder) !important;
  color: var(--#{$prefix}secondary-color) !important;
}

.pac-container {
  z-index: 1111 !important;
}

.channel-card {
  background-color: var(--card-channel-body) !important;
}

.channel-card-text{
  color: var(--card-channel-text) !important;
}

.p-sm {
  padding: 0.15rem !important;
}

html{
  overflow: hidden;
}

.dropzone-pro {
  border: 1px dashed  var(--outline)!important; /* Change the color to your preference */
  // border-radius: 10px !important;
  padding: 20px !important;
  text-align: center !important;
  transition: background-color 0.3s, border-color 0.3s !important;
  cursor: pointer;
  .dropzone-message{
    min-height: 100px;
  }
  i {
    color: var(--outline) !important;
  }
  &:hover{
    border: 1px dashed var(--primary) !important;
    i {
      color: var(--primary) !important;
    }
  }
}

.dz-preview.dz-complete.dz-image-preview{
  background: var(--dropzone-bg) !important;
}

.dropzone .dz-preview.dz-image-preview{
  background: none !important;
}

.dz-remove{
  color: var(--dropzone-remove-text) !important;
}

.md-success-button{
  --md-filled-button-container-color: var(--success);
  --md-filled-button-label-text-color: var(--onSuccess);
  --md-filled-button-hover-label-text-color: var(--onSuccess);
  --md-filled-button-focus-label-text-color: var(--onSuccess);
  --md-filled-button-pressed-label-text-color: var(--onSuccess);
}

.md-error-button{
  --md-filled-button-container-color: var(--error);
  --md-filled-button-label-text-color: var(--onError);
  --md-filled-button-hover-label-text-color: var(--onError);
  --md-filled-button-focus-label-text-color: var(--onError);
  --md-filled-button-pressed-label-text-color: var(--onError);
}

#googleSignInDiv > div > iframe {
  width: 100% !important;
}

.custom-small-switch {
  --switch-width: 20px;   /* Set the desired width */
  --switch-height: 12px;  /* Set the desired height */
  --switch-knob-size: 10px; /* Set the size of the switch knob */
  --switch-knob-margin: 1px; /* Space around the knob */
}

.offcanvas{
  background: var(--offcanvas-bg) !important;
}

.offcanvas-card.card{
  background: var(--offcanvas-bg) !important;
}

// .offcanvas-list-unstyled{
//   padding-right: 15px !important;
// }

.dropdown-icon {
  transform: rotate(0deg);
  transform-origin: center;
  display: inline-block;
  transition: all 0.4s;
}

.mm-active .dropdown-icon{
  transform: rotate(-180deg);
  transform-origin: center;
  display: inline-block;
  transition: all 0.4s;
}

.collapsed .dropdown-icon{
  transform: rotate(-180deg);
  transform-origin: center;
  display: inline-block;
  transition: all 0.4s;
}

.mm-active .offcanvas-parent{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.mm-active .offcanvas-submenu{
  border-top: solid 1.5px var(--offcanvas-divider);
}

.offcanvas-menu{
  background: var(--offcanvas-content-bg) !important;
}

.offcanvas-parent{
  background: var(--offcanvas-parent-bg);
}

.offcanvas-icon{
  background: var(--offcanvas-icon-bg);
}

.offcanvas-submenu{
  background: var(--offcanvas-submenu-bg);
}

.offcanvas-card-container{
  background: var(--offcanvas-card-container-bg);
}

.offcanvas-card-container:hover{
  background: var(--offcanvas-card-container-hover-bg);
}

.offcanvas-single-card-container{
  background: var(--offcanvas-single-card-container-bg);
}

.offcanvas-single-card-container:hover{
  background: var(--offcanvas-single-card-container-hover-bg);
}

.mm-active.offcanvas-list{
  > a{
    color: var(--offcanvas-text) !important;
  }
}

.tab-content{
  height: 100%;
}

.offcanvas-scrollbar{
  scrollbar-color: var(--offcanvas-scrollbar-color) transparent;
}

.offcanvas-card:hover .option-button {
  transform: translateX(-235%);
  transition: all 0.4s;
}

.offcanvas-drag-icon{
  color: var(--offcanvas-drag-icon);
}

.offcanvas input::placeholder{
  color: var(--offcanvas-input-text) !important;
}

.offcanvas-btn-check + .btn{
  color: var(--offcanvas-radio-btn-text) !important;
  background-color: var(--offcanvas-radio-btn-bg) !important;
  border: none;
}

.offcanvas-btn-check + .btn:hover{
  color: var(--offcanvas-radio-btn-text) !important;
  background-color: var(--offcanvas-radio-btn-bg) !important;
  border: none;
}

.offcanvas-btn-check:checked + .btn{
  color: var(--offcanvas-radio-btn-active-text) !important;
  background-color: var(--offcanvas-radio-btn-active-bg) !important;
}

.offcanvas-tabset .nav-link span{
  font-size: 14px;
  font-weight: 600;
}

.offcanvas-tabset .nav-link{
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none !important;
}

.input-rounded{
  border-radius: 0.4rem;
}

.input-focus-border{
  border: $input-btn-border-width solid var(--outline);
}

.input-focus-border:focus {
  outline: calc($input-btn-border-width * 2) solid var(--primary);
  outline-offset: 0.1rem;
}

.back-icon:hover{
  background: var(--icon-back-hover-bg);
}

.card-button-hover{
  background: var(--card-body) !important;
}

.card-button-hover:hover{
  background: var(--card-button-hover-bg) !important;
}

.drag-icon{
  left: -1.5rem !important;
}

.media-drag-icon{
  color: var(--media-drag-icon);
}

.sidebar-collapsed-text{
  color: var(--sidebar-hover-text) !important;
}

.sidebar-menu{
  background: var(--sidebar-menu-bg);
}

.vertical-menu{
  transition: all 0.4s ease;
}

.logo-box{
  transition: all 0.4s ease;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.topbar-popup{
  position: absolute;
  padding-top: 1px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 380px;
  height: 50px;
  background-color: var(--card-body);
  z-index: 7;
  transition: top 0.5s ease, opacity 0.5s ease;
}

.topbar-popup.show{
  top:69px
}


.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.00, 1.00) !important;
  -moz-transform: scale(1.00, 1.00) !important;
  -ms-transform: scale(1.00, 1.00) !important;
  -o-transform: scale(1.00, 1.00) !important;
  transform: scale(1.00, 1.00) !important;
  -webkit-filter: none !important;
  filter: none !important;
}

.dropzone .dz-details{
  display: none !important;
}

.btn-focus-none:focus{
  box-shadow: none !important;
}

.id-icon{
  width: 15px;
  height: 12px;
  background: url('~src/assets/images/id-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.appBuilderPage .page-content{
  padding-top: calc(#{$header-height}) !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.cta-icon {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 2px solid #e05951;
  background: var(--settings-btn-bg);
  color: var(--settings-btn-text);
  overflow: hidden;
}

.cta-icon:hover {
  background: hsl(from var(--settings-btn-bg) h s calc(l - 3)) !important;
  color: var(--settings-btn-text);
}

.card-dim-bg{
  background: var(--card-dim-bg) !important;
}

.card-bright-bg{
  background: var(--card-bright-bg) !important;
}

.tab-dim-bg{
  background: var(--card-dim-bg) !important;
  border: var(--outline-variant) !important;
  color: var(--card-container-text) !important;
}

.primary-button{
  background: var(--primary) !important;
  color: var(--onPrimary) !important;
}

.primary-button:hover{
  background: hsl(from var(--primary) h s calc(l - 5)) !important;
  color: var(--onPrimary) !important;
}

.primary-button:active{
  background: hsl(from var(--primary) h s calc(l - 7)) !important;
  color: var(--onPrimary) !important;
}

.primary-container-button{
  background: var(--primaryContainer) !important;
  color: var(--onPrimaryContainer) !important;
}

.primary-container-button:hover{
  background: hsl(from var(--primaryContainer) h s calc(l - 5)) !important;
  color: var(--onPrimaryContainer) !important;
}

.primary-container-button:active{
  background: hsl(from var(--primaryContainer) h s calc(l - 7)) !important;
  color: var(--onPrimaryContainer) !important;
}

.appbuilder-secondary-btn{
  background: var(--secondary);
  color: var(--onSecondary);
}

.appbuilder-secondary-btn:hover{
  background: hsl(from var(--secondary) h s calc(l - 5)) !important;
  color: var(--onSecondary) !important;
}

.appbuilder-secondary-btn:active{
  background: hsl(from var(--secondary) h s calc(l - 7)) !important;
  color: var(--onSecondary) !important;
}

.emoji-color-noto{
  font-family: 'Noto Sans', 'Noto Color Emoji', 'Segoe UI Emoji', 'Apple Color Emoji', 'Emoji', 'Android Emoji', sans-serif;
}

.nav_tabs .nav_link{
  transition: 0.3s all;
}

.cdk-drag::marker {
  content: none;
}

.disabled-animated-btn {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  line-height: 58px;
  display: inline-block;
  text-align: center;
  // background: #585e72;
  background: var(--module-bar-bg);
  position: absolute;
  cursor: pointer;
  left: -30px;
  z-index: 1;
}

.os-tabset .nav-tabs{
  background: var(--os-tabset-bg) !important;
  border: none !important;
  border-radius: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.os-tabset .nav-link {
  margin: 0.25rem !important;
  padding: 0.25rem 0 !important;
  border-radius: 0.5rem !important;
}

.os-tabset .nav-link:hover {
  border-color: transparent !important;
}

.slider-card{
  background-color: var(--slider-card-bg) !important;
}

.builder-nav-tabs{
  @media (max-width: 576px) {
    background-color: var(--slider-card-bg) !important;
  }
}

.chat-sidebar{
  background: var(--chat-sidebar-bg);
}

.chat-top-bar{
  background: var(--chat-bar-bg);
}

.chat-bottom-bar{
  background: var(--chat-bar-bg);
}

.chat-tabset .nav-tabs{
  background: var(--chat-bar-bg) !important;
  border-radius: 0.5rem !important;
  border: solid 1px var(--outline-variant) !important;
  padding-right: 0.5rem !important;
  margin-left: 1rem;
  margin-right: 1rem;
}

.chat-tabset .nav-link {
  margin: 0.1rem !important;
  padding: 0 !important;
  border-radius: 0.5rem !important;
}

.chat-tabset .nav-link:hover {
  border-color: transparent !important;
}

.chat-background {
  background: url('~src/assets/images/chat-background.svg');
  background-size: contain;
  background-repeat: repeat;
}

.chat-snd-message-bg{
  background-color: #e2ffc7 !important;
}

.channel-message-bg{
  background-color: var(--channel-message-bg);
}

.chat-compose-bg{
  background-color: var(--chat-compose-bg);
}

.p-4_5 {
  padding: 2rem !important;
}

.channel-post-radio{
  background-color: var(--channel-post-radio-bg) !important;
  input{
    --bs-form-check-bg: var(--channel-post-radio-check) !important
  }
}

.channel-reply-bg{
  background-color: var(--channel-reply-bg) !important;
}

.sm-icon{
  width: 26px !important;
  height: 26px !important;
}

.xs-icon{
  width: 16px !important;
  height: 16px !important;
}

.shadow-sm-bottom {
  box-shadow: 0 .5rem .5rem -.5rem rgba($black, .075);
}


///Flow

.f-connection {
  .f-connection-drag-handle {
    fill: transparent !important;
  }
  .f-connection-selection {
    fill: none !important;
  }
  .f-connection-path {
    fill: none !important;
    stroke: var(--connection-color) !important;
    stroke-width: 2 !important;
  }
  .f-connection-text, .f-connection-center {
    fill: var(--connection-color) !important;
    color: var(--connection-color) !important;
  }
  &.f-snap-connection {
    .f-connection-path {
      stroke: var(--snap-connection-color) !important;
    }
  }
}

.f-background {
  line {
    stroke: rgba(0, 0, 0, 0.6);
  }
}

.f-line-alignment {
  .f-line {
    background-color: var(--connection-color);
  }
}

.f-minimap {
  background-color: var(--minimap-background-color);
  bottom: 16px;
  right: 16px;
  width: 120px;
  height: 120px;
  .f-minimap-node {
    fill: var(--minimap-node-color);
    &.f-selected {
      fill: var(--minimap-node-selected-color);
    }
  }
  .f-minimap-view {
    fill: var(--minimap-view-color)
  }
}

.f-node {
  // width: 77px;
  // height: 145px;
  color: var(--node-color);
  text-align: center;
  background: var(--node-background-color);
  cursor: move;
  &:active {
    box-shadow: var(--node-shadow);
  }
}

.connectors-line {
  position: absolute;
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  justify-content: center;
  gap: 16px;
  height: calc(100% - 8px); /* Adjust height to match container */
  right: 0; /* Align to the right of the container */
  top: 0; /* Start positioning from the top */

  // position: absolute;
  // display: flex;
  // justify-content: center;
  // gap: 16px;
  // width: calc(100% - 8px);
  // .f-node-output, .f-node-input {
  //   // width: 16px;
  //   // height: 16px;
  //   // border-radius: 50%;
  //   // border: 0.2px solid var(--node-border-color);
  //   // background-color: var(--node-background-color);
  // }
  // &.top {
  //   top: -8px
  // }
  // &.bottom {
  //   bottom: -8px;
  // }
}

.f-toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  right: 16px;
  top: 16px;
  button {
    font-weight: 500;
    border: none;
    border-radius: 2px;
    padding: 4px 8px;
    line-height: normal;
  }
}

.chat-ai-background {
  background: var(--onSurface);
}

.chat-ai-text {
  color: var(--surfaceContainerLowest);
}

.chat-ai-input {
  color: var(--surfaceContainerLowest);
}

.chat-ai-input:focus {
  color: var(--surfaceContainerLow);
}

.chat-ai-input::placeholder {
  color: var(--surfaceContainerLow) !important;
}

.svg-container svg{
  width: 100%;
}

.breadcrumb{
  display: flex;
  justify-content: flex-end;
}

.ai-gradient-button {
  background: linear-gradient(180deg, #0021FF 0%, #116DFF 50%, #5FF2AE 90%) !important;
  transition: background 0.3s ease; /* Smooth background changes */
}

.ai-gradient-button:hover {
  background: linear-gradient(180deg, #5FF2AE 0%, #116DFF 50%, #0021FF 90%) !important;
}

.mt-6{
  margin-top: 4rem !important
}

.mt-7{
  margin-top: 5rem !important
}

.mt-8{
  margin-top: 6rem !important
}

.mt-9{
  margin-top: 7rem !important
}

.mt-10{
  margin-top: 8rem !important
}

.pt-10{
  padding-top: 8rem !important
}

.ai-card{
  background: var(--surfaceContainerLowest);
  box-shadow: 0 0 24px 0px rgba(0,6,36,.12) !important;
  box-sizing: border-box;
}

.ai-card.dark {
  background: var(--surfaceContainerLowest);
  box-shadow: 0 0 24px 0px rgba(255, 255, 255, 0.1) !important;
  box-sizing: border-box;
}

.ai-background{
  background: var(--surfaceContainerLowest);
}

.pb-8{
  padding-bottom: 6rem;
}
