/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
@import '~dropzone/dist/min/dropzone.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}
.avatar-xxs {
  height: 1.5rem;
  width: 1.5rem;
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}

// @font-face {
//   font-family: 'Material Icons';
//   font-style: normal;
//   font-weight: 400;
//   src:  url(./assets/fonts/materialdesignicons-webfont.eot);
//   src:
//     url(./assets/fonts/materialdesignicons-webfont.woff2) format('woff2'),
//     url(./assets/fonts/materialdesignicons-webfont.woff) format('woff'),
//     url(./assets/fonts/materialdesignicons-webfont.ttf) format('truetype')
// }



// .material-icons {
//   font-family: 'Material Icons';
//   font-weight: normal;
//   font-style: normal;
//   font-size: 24px;
//   line-height: 1;
//   letter-spacing: normal;
//   text-transform: none;
//   display: inline-block;
//   white-space: nowrap;
//   word-wrap: normal;
//   direction: ltr;
//   -webkit-font-feature-settings: 'liga';
//   -webkit-font-smoothing: antialiased;
// }
mat-step-header:hover {
  background-color: unset !important;
}
mat-step-header {
  background-color: transparent !important;
}
//
// Dropzone
//

.dropzone {
  min-height: 230px !important;
  // border: 2px dashed #eff2f7 !important;
  border: 1px dashed !important;
  border-color: var(--dropzone-border) !important;
  // background: #fff !important;
  background: var(--dropzone-bg) !important;
  border-radius: 6px !important;
  text-align: center;

  .dz-message {
    width: 100%;
    margin: 1em 0;
  }
}

[data-dz-thumbnail] {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-remove {
  margin-top: 10px;
}

dropzone>.dropzone.dz-wrapper .dz-message {
  background-color: transparent !important;
  border: none !important;
  overflow: inherit !important;
  width: inherit !important;
  min-width: inherit !important;
  min-height: 180px !important;
  max-width: inherit !important;
  max-height: inherit !important;
  margin: 0px !important;
}

.dz-text {
  font-size: 1.21875rem
}

dropzone>.dropzone.dz-wrapper {
  min-height: 76px !important;
  border: none !important;
}


#component-colorpicker {
  color-picker {
    .open {
      right: 0px !important;
      left: auto !important;
      z-index: 9999 !important;
    }
  }
}

.color-picker {
  .arrow.arrow-bottom {
    right: 20px !important;
    left: auto !important;
  }
}

.switch.switch-small>.switch-pane>span {
  font-size: 12px !important;
}

.modal-dialog {
  top: auto !important;
  bottom: auto !important;
}

.slick-dots {
  margin-top: 20px;
  bottom: -40px !important;


  li.slick-active button:before {
    color: #556ee6 !important;
    font-size: x-small !important;
  }
}

.slick-dots li button:before {
  color: #556ee6;
  font-size: x-small !important;
}

.slick-slide img {
  width: 100%;
}

.dropup {
  .dropdown-menu {
    inset: auto auto auto 0px !important;
    transform: translateY(-160px) !important;
  }
}

.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background-color: #556ee6 !important;
}
.gallery-padding{
  padding-top: 4px !important;
}
